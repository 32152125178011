var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Zona","label-for":"zona"}},[_c('validation-provider',{attrs:{"name":"zona","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"zona","type":"text"},model:{value:(_vm.zona.descricao),callback:function ($$v) {_vm.$set(_vm.zona, "descricao", $$v)},expression:"zona.descricao"}}),_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalZonas}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Moeda","label-for":"moeda"}},[_c('validation-provider',{attrs:{"name":"moeda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"moeda","type":"text"},model:{value:(_vm.moeda.descricao),callback:function ($$v) {_vm.$set(_vm.moeda, "descricao", $$v)},expression:"moeda.descricao"}}),_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalMoedas}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Vendedor","label-for":"vendedor"}},[_c('validation-provider',{attrs:{"name":"vendedor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vendedor","type":"text"},model:{value:(_vm.vendedor.nome),callback:function ($$v) {_vm.$set(_vm.vendedor, "nome", $$v)},expression:"vendedor.nome"}}),_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalVendedores}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2","lg":"2"}},[_c('b-form-group',{attrs:{"label":"Ativo","label-for":"ativo","label-class":"mb-1"}},[_c('b-form-checkbox',{attrs:{"id":"ativo","value":"1"},model:{value:(_vm.cliente.ativo),callback:function ($$v) {_vm.$set(_vm.cliente, "ativo", $$v)},expression:"cliente.ativo"}})],1)],1),_c('b-col',{attrs:{"md":"2","lg":"2"}},[_c('b-form-group',{attrs:{"label":"Bloqueado","label-for":"bloqueado","label-class":"mb-1"}},[_c('b-form-checkbox',{attrs:{"id":"bloqueado","value":"1"},model:{value:(_vm.cliente.bloqueado),callback:function ($$v) {_vm.$set(_vm.cliente, "bloqueado", $$v)},expression:"cliente.bloqueado"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Observações","label-for":"h-observacoes"}},[_c('validation-provider',{attrs:{"name":"Observações","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"observacoes","placeholder":"Observações","rows":"8"},model:{value:(_vm.cliente.observacoes),callback:function ($$v) {_vm.$set(_vm.cliente, "observacoes", $$v)},expression:"cliente.observacoes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-modal',{ref:"modalVendedor",attrs:{"hide-footer":"","size":"xl"}},[_c('selecionarVendedor',{on:{"vendedorSelecionado":_vm.selectVendedor}})],1),_c('b-modal',{ref:"modalMoedas",attrs:{"hide-footer":"","size":"xl"}},[_c('selecionarMoeda',{on:{"moedaSelecionada":_vm.selectMoeda}})],1),_c('b-modal',{ref:"modalZonas",attrs:{"hide-footer":"","size":"xl"}},[_c('selecionarZona',{on:{"zonaSelecionada":_vm.selectZona}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }