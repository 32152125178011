<template>
  <div>
    <b-form @submit.prevent>
      <validation-observer ref="form">

        <b-row>
          <b-col
            md="4"
          >
            <b-form-group
              label="Zona"
              label-for="zona"
            >
              <validation-provider
                #default="{ errors }"
                name="zona"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="zona"
                    v-model="zona.descricao"
                    type="text"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalZonas"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
          >
            <b-form-group
              label="Moeda"
              label-for="moeda"
            >
              <validation-provider
                #default="{ errors }"
                name="moeda"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="moeda"
                    v-model="moeda.descricao"
                    type="text"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalMoedas"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
          >
            <b-form-group
              label="Vendedor"
              label-for="vendedor"
            >
              <validation-provider
                #default="{ errors }"
                name="vendedor"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="vendedor"
                    v-model="vendedor.nome"
                    type="text"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalVendedores"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="2"
            lg="2"
          >
            <b-form-group
              label="Ativo"
              label-for="ativo"
              label-class="mb-1"
            >
              <b-form-checkbox
                id="ativo"
                v-model="cliente.ativo"
                value="1"
              />

            </b-form-group>
          </b-col>
          <b-col
            md="2"
            lg="2"
          >
            <b-form-group
              label="Bloqueado"
              label-for="bloqueado"
              label-class="mb-1"
            >
              <b-form-checkbox
                id="bloqueado"
                v-model="cliente.bloqueado"
                value="1"
              />

            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Observações"
              label-for="h-observacoes"
            >
              <validation-provider
                #default="{ errors }"
                name="Observações"
                rules="required"
              >
                <b-form-textarea
                  id="observacoes"
                  v-model="cliente.observacoes"
                  placeholder="Observações"
                  rows="8"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

      </validation-observer>
    </b-form>

    <b-modal
      ref="modalVendedor"
      hide-footer
      size="xl"
    >
      <selecionarVendedor @vendedorSelecionado="selectVendedor" />
    </b-modal>

    <b-modal
      ref="modalMoedas"
      hide-footer
      size="xl"
    >
      <selecionarMoeda @moedaSelecionada="selectMoeda" />
    </b-modal>

    <b-modal
      ref="modalZonas"
      hide-footer
      size="xl"
    >
      <selecionarZona @zonaSelecionada="selectZona" />
    </b-modal>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroupPrepend, BInputGroup, BModal, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import selecionarZona from '../../Listagens/Zonas/SelecionarZonas.vue'
import selecionarMoeda from '../../Listagens/Moedas/SelecionarMoedas.vue'
import selecionarVendedor from '../../Listagens/Vendedores/SelecionarVendedores.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    BFormTextarea,
    BFormCheckbox,
    selecionarZona,
    selecionarMoeda,
    selecionarVendedor,
  },
  directives: {
    Ripple,
  },
  props: {
    cliente: Object,
    zona: Object,
    moeda: Object,
    vendedor: Object,
  },
  data() {
    return {
      required,
      boxTwo: false,
      pageLength: 3,
      searchTerm: '',

    }
  },

  created() {
    localize('pt', pt)
  },
  methods: {

    selectMoeda(row) {
      this.cliente.idMoeda = row.id
      this.moeda.id = row.id
      this.moeda.codigo = row.codigo
      this.moeda.descricao = row.descricao
      this.hideModalMoedas()
    },

    showModalMoedas() {
      this.$refs.modalMoedas.show()
    },
    hideModalMoedas() {
      this.$refs.modalMoedas.hide()
    },

    selectVendedor(row) {
      this.cliente.idVendedor = row.id
      this.vendedor.id = row.id
      this.vendedor.codigo = row.codigo
      this.vendedor.nome = row.nome
      this.hideModalVendedores()
    },

    showModalVendedores() {
      this.$refs.modalVendedor.show()
    },
    hideModalVendedores() {
      this.$refs.modalVendedor.hide()
    },

    selectZona(row) {
      this.cliente.idZona = row.id
      this.zona.id = row.id
      this.zona.codigo = row.codigo
      this.zona.descricao = row.descricao
      this.hideModalZonas()
    },

    showModalZonas() {
      this.$refs.modalZonas.show()
    },
    hideModalZonas() {
      this.$refs.modalZonas.hide()
    },

  },
}
</script>
  <style lang="scss">
  </style>
