<template>
  <div>
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>

          <b-col
            md="3"
          >
            <b-form-group
              label="Cliente"
              label-for="cliente"
            >
              <validation-provider
                #default="{ errors }"
                name="Cliente"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="cliente"
                    v-model="cliente.cliente"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalClientes"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Nome"
              label-for="h-nome"
            >
              <validation-provider
                #default="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-input
                  id="h-nome"
                  v-model="cliente.nome"
                  placeholder="Nome"
                  maxlength="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
          <b-col md="3">
            <b-form-group
              label="NIF"
              label-for="h-nif"
            >
              <validation-provider
                #default="{ errors }"
                name="nif"
                rules="required"
              >
                <b-form-input
                  id="h-nif"
                  v-model="cliente.nif"
                  placeholder="NIF"
                  maxlength="20"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="8">
            <b-form-group
              label="Nome Fiscal"
              label-for="h-nomeFiscal"
            >
              <validation-provider
                #default="{ errors }"
                name="nomeFiscal"
                rules="required"
              >
                <b-form-input
                  id="h-nomeFiscal Fiscal"
                  v-model="cliente.nomeFiscal"
                  placeholder="Nome"
                  maxlength="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
          >
            <b-form-group
              label="País"
              label-for="pais"
            >
              <validation-provider
                #default="{ errors }"
                name="pais"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="pais"
                    v-model="pais.descricao"
                    type="text"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalPais"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-form-group
              label="Morada"
              label-for="h-morada"
            >
              <validation-provider
                #default="{ errors }"
                name="Morada"
                rules="required"
              >
                <b-form-input
                  id="h-morada"
                  v-model="cliente.morada"
                  placeholder="Morada"
                  maxlength="500"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Localidade"
              label-for="h-localidade"
            >
              <validation-provider
                #default="{ errors }"
                name="Localidade"
                rules="required"
              >
                <b-form-input
                  id="h-localidade"
                  v-model="cliente.localidade"
                  placeholder="Localidade"
                  maxlength="500"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Código Postal"
              label-for="h-codigoPostal"
            >
              <validation-provider
                #default="{ errors }"
                name="Código Postal"
                rules="required"
              >
                <b-form-input
                  id="h-codigoPostal"
                  v-model="cliente.codigoPostal"
                  placeholder="Código Postal"
                  maxlength="15"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Telefone"
              label-for="h-telefone"
            >
              <validation-provider
                #default="{ errors }"
                name="Telefone"
                rules="required"
              >
                <b-form-input
                  id="h-telefone"
                  v-model="cliente.telefone"
                  placeholder="Telefone"
                  maxlength="20"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Telemovel"
              label-for="h-telemovel"
            >
              <validation-provider
                #default="{ errors }"
                name="Telemovel"
                rules="required"
              >
                <b-form-input
                  id="h-telemovel"
                  v-model="cliente.telemovel"
                  placeholder="Telemovel"
                  maxlength="20"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
        </b-row>
        <b-row>
          <b-col
            md="6"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="cliente.email"
                  type="email"
                  maxlength="256"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Email Serviços"
              label-for="emailServicos"
            >
              <validation-provider
                #default="{ errors }"
                name="emailServicos"
                rules="required"
              >
                <b-form-input
                  id="emailServicos"
                  v-model="cliente.emailServicos"
                  type="email"
                  maxlength="256"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>

    <b-modal
      ref="modalPais"
      hide-footer
      size="xl"
    >
      <selecionarPais @paisSelecionado="selectPais" />
    </b-modal>
    <b-modal
      ref="modalClientes"
      hide-footer
      size="xl"
    >
      <selecionarCliente @clienteSelecionado="selectCliente" />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroupPrepend, BInputGroup, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import router from '@/router'
import axios from 'axios'
import selecionarCliente from '../../Listagens/Clientes/SelecionarCliente.vue'
import selecionarPais from '../../Listagens/Paises/SelecionarPais.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    selecionarCliente,
    selecionarPais,
  },
  directives: {
    Ripple,
  },
  props: {
    cliente: Object,
    pais: Object,
    zona: Object,
    moeda: Object,
    vendedor: Object,
  },

  data() {
    return {
      required,
    }
  },

  created() {
    localize('pt', pt)
    if (router.currentRoute.params.id !== undefined) {
      this.getOneCliente()
    }
  },
  methods: {
    getOneCliente() {
      const clienteId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
      const apiUrl = `/api/v1/clientes/${clienteId}/complete`

      axios.get(apiUrl)
        .then(res => { this.selectCliente(res.data) })
        .catch(error => {
          // Lide com erros aqui
        })
      this.$forceUpdate()
    },

    selectCliente(row) {
      this.cliente.cliente = row.cliente
      this.cliente.id = row.id
      this.cliente.nome = row.nome
      this.cliente.morada = row.morada
      this.cliente.codigoPostal = row.codigoPostal
      this.cliente.nif = row.nif
      this.cliente.idPais = row.idPais
      this.pais.codigo = row.codigoPais
      this.pais.descricao = row.descricaoPais
      this.cliente.idMoeda = row.idMoeda
      this.moeda.codigo = row.codigoMoeda
      this.moeda.descricao = row.descricaoMoeda
      this.cliente.idZona = row.idZona
      this.zona.codigo = row.codigoZona
      this.zona.descricao = row.descricaoZona
      this.cliente.idVendedor = row.idVendedor
      this.vendedor.codigo = row.codigoVendedor
      this.vendedor.nome = row.nomeVendedor
      this.cliente.telefone = row.telefone
      this.cliente.telemovel = row.telemovel
      this.cliente.email = row.email
      this.cliente.nomeFiscal = row.nomeFiscal
      this.cliente.localidade = row.localidade
      this.cliente.emailServicos = row.emailServicos
      this.cliente.ativo = row.ativo
      this.cliente.bloqueado = row.bloqueado
      this.cliente.observacoes = row.observacoes
      this.hideModalClientes()
    },
    selectPais(row) {
      this.cliente.idPais = row.id
      this.pais.id = row.id
      this.pais.codigo = row.codigo
      this.pais.descricao = row.descricao
      this.hideModalPais()
    },
    showModalClientes() {
      this.$refs.modalClientes.show()
    },
    hideModalClientes() {
      this.$refs.modalClientes.hide()
    },
    showModalPais() {
      this.$refs.modalPais.show()
    },
    hideModalPais() {
      this.$refs.modalPais.hide()
    },
  },
}
</script>
  <style lang="scss">
  </style>
