<template>

  <b-card title="Novo Cliente">

    <b-tabs
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Informação</span>
        </template>
        <morada
          :cliente="cliente"
          :pais="pais"
          :zona="zona"
          :moeda="moeda"
          :vendedor="vendedor"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Outros Dados</span>
        </template>
        <outrosDados
          :cliente="cliente"
          :zona="zona"
          :moeda="moeda"
          :vendedor="vendedor"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="FileTextIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Contratos</span>
        </template>
        <contratos />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="ServerIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Serviços</span>
        </template>
        <servicos />
      </b-tab>
      <b-tabs pills />
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="guardarCliente()"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTab, BTabs, BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import morada from './Editar/Morada.vue'
import outrosDados from './Editar/OutrosDados.vue'
import servicos from './Editar/Servicos.vue'
import contratos from './Editar/Contratos.vue'

export default {

  components: {
    BCol,
    BRow,
    BTab,
    BTabs,
    BCard,
    BButton,
    morada,
    outrosDados,
    servicos,
    contratos,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cliente: {
        cliente: '',
        nome: '',
        morada: '',
        codigoPostal: '',
        nif: '',
        idPais: '',
        idMoeda: '',
        idZona: '',
        idVendedor: '',
        telefone: '',
        telemovel: '',
        email: '',
        nomeFiscal: '',
        localidade: '',
        emailServicos: '',
        ativo: 0,
        bloqueado: 0,
        observacoes: '',
      },
      zona: {
        id: '',
        codigo: '',
        descricao: '',
      },
      pais: {
        id: '',
        codigo: '',
        descricao: '',
      },
      moeda: {
        id: '',
        codigo: '',
        descricao: '',
      },
      vendedor: {
        id: '',
        codigo: '',
        nome: '',
      },
    }
  },

  methods: {
    guardarCliente() {
      this.cliente.nome = encodeURIComponent(this.cliente.nome)
      this.cliente.nomeFiscal = encodeURIComponent(this.cliente.nomeFiscal)
      axios.put(`/api/v1/clientes/${this.cliente.id}`, this.cliente)
        .then(this.showToast('success', 'CheckIcon'))
      this.$forceUpdate()
      this.cliente.nome = decodeURIComponent(this.cliente.nome)
      this.cliente.nomeFiscal = decodeURIComponent(this.cliente.nomeFiscal)
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },
  },
}
</script>

<style>

</style>
